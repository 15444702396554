import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Drawer, Grid, Dialog, Button, Typography } from '@material-ui/core';
import Navigator from './Navigator';
// import Header from './NewHeader';
import BottomNavigator from './BottomNavigator';
import {
    SIDEBAR_LEFT_CONTENT,
    SIDEBAR_LEFT_WIDTH,
    ROOMS,
    TOPICS,
    TOKEN_NAME,
    MerchantRealtimeActionTypes,
    MerchantState
} from 'const';
// import MenuIcon from '@material-ui/icons/Menu';
import { useIsOpenSidebarLeft, useOpenLogoutPopup } from 'hooks/app';
import { useDispatch } from 'react-redux';
import * as appActions from 'app/actions';
import { io } from 'socket.io-client';
import { getItem } from 'utils/localStorage';
import { API_URL } from '../config';
import { clearToken } from 'utils/token';
import { useUserMerchantId } from 'hooks/auth';
import DialogActions from 'components/dialog/Action';
import DialogContent from 'components/dialog/Content';
import DialogTitle from 'components/dialog/Title';
import { useHistory } from 'react-router-dom';
import { useMerchantInfo } from 'hooks/merchant';
import { UpgradePayment } from 'components/upgrade-payment';
import { getFriendlyWeekDate } from 'utils/timing';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: theme.colors.background,
        position: 'relative',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        maxHeight: `-webkit-fill-available`
    },
    contentOpen: {
        marginLeft: SIDEBAR_LEFT_WIDTH + SIDEBAR_LEFT_CONTENT,
        width: `calc(100vw - ${SIDEBAR_LEFT_WIDTH + SIDEBAR_LEFT_CONTENT}px)`,
        [theme.breakpoints.down('sm')]: {
            marginLeft: SIDEBAR_LEFT_CONTENT,
            width: `calc(100vw - ${SIDEBAR_LEFT_CONTENT}px)`
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    contentClose: {
        width: `calc(100vw - ${SIDEBAR_LEFT_WIDTH}px)`,
        marginLeft: SIDEBAR_LEFT_WIDTH,
        [theme.breakpoints.down('sm')]: {
            width: `calc(100vw)`,
            marginLeft: 0
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    sidebarContent: {
        borderLeft: `1px solid ${theme.colors.divider}`,
        flex: 1
    },
    drawer: {
        zIndex: 99,
        top: 0
    },
    drawerOpen: {
        width: SIDEBAR_LEFT_WIDTH + SIDEBAR_LEFT_CONTENT,
        [theme.breakpoints.down('sm')]: {
            width: SIDEBAR_LEFT_CONTENT
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        width: SIDEBAR_LEFT_WIDTH,
        [theme.breakpoints.down('sm')]: {
            width: 0
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBar: {
        left: SIDEBAR_LEFT_WIDTH,
        [theme.breakpoints.down('sm')]: {
            left: 0
        },
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: SIDEBAR_LEFT_CONTENT,
        width: `calc(100% - ${SIDEBAR_LEFT_WIDTH + SIDEBAR_LEFT_CONTENT}px)`,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: `calc(100% - ${SIDEBAR_LEFT_CONTENT}px)`
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: 'none'
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        zIndex: theme.zIndex.drawer + 2
    },
    blocked: {
        opacity: 0.5
    },
    upgradeUsage: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: theme.zIndex.drawer + 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    usageWarning: {
        background: 'white',
        gap: 10,
        padding: 12,
        textAlign: 'center',
        width: 'fit-content',
        borderRadius: 10
    }
}));

function MainLayout({ children }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isOpenSidebarLeft = useIsOpenSidebarLeft();
    const merchantId = useUserMerchantId();
    const openLogoutPopup = useOpenLogoutPopup();
    const history = useHistory();
    const merchant = useMerchantInfo();

    const token = getItem(TOKEN_NAME);

    const handleRealtime = React.useCallback(
        (body) => {
            if (!body) return;
            switch (body?.action) {
                case MerchantRealtimeActionTypes.Logout: {
                    clearToken();
                    dispatch(appActions.setOpenLogoutPopup(true));
                    break;
                }
                default:
                    return;
            }
        },
        [dispatch]
    );

    const handleRedirectLogin = React.useCallback(() => {
        dispatch(appActions.setOpenLogoutPopup(false));
        history.push('/login');
    }, [dispatch, history]);

    React.useEffect(() => {
        const bookingSocket = io(`${API_URL}/app`, {
            transports: ['websocket'],
            autoConnect: true,
            reconnectionDelay: 2000,
            query: {
                token
            }
        });
        bookingSocket.on('connect', () => {
            bookingSocket.emit(ROOMS.JOIN_ROOM, TOPICS.merchant(merchantId));
        });

        bookingSocket.on(ROOMS.JOINED_ROOM, (room) => {
            console.log('JOINED_ROOM', room);
        });

        bookingSocket.on(ROOMS.MODIFIED, (body) => {
            handleRealtime(body);
        });

        bookingSocket.on(ROOMS.LEAVED_ROOM, (body) => {
            console.log('data LEAVED_ROOM', body);
        });

        return () => {
            bookingSocket.emit(ROOMS.LEAVE_ROOM, TOPICS.merchant(merchantId));
        };
    }, [merchantId, token, handleRealtime]);

    const handleDrawerToggle = () => {
        dispatch(appActions.toggleDrawlerLeft());
    };

    const isShowPaymentWarning = merchant?.state === MerchantState.PendingPayment;

    return (
        <>
            <div className={`${classes.root} ${isShowPaymentWarning ? classes.blocked : ''}`}>
                <Hidden>
                    <Drawer
                        anchor="left"
                        variant="permanent"
                        classes={{
                            paper: `${classes.drawer} ${isOpenSidebarLeft ? classes.drawerOpen : classes.drawerClose}`
                        }}
                    >
                        <Grid container wrap="nowrap" className={classes.fullHeight}>
                            <Navigator open={isOpenSidebarLeft} onClose={handleDrawerToggle} />
                        </Grid>
                    </Drawer>
                </Hidden>

                <Grid item className={`${isOpenSidebarLeft ? classes.contentOpen : classes.contentClose}`}>
                    {children}
                </Grid>

                <Hidden smUp implementation="js">
                    <footer className={classes.footer}>
                        <BottomNavigator onDrawerToggle={handleDrawerToggle} />
                    </footer>
                </Hidden>
                <Dialog maxWidth={false} open={openLogoutPopup}>
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h5">Logout app</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">
                            Your merchant admin has changed your password, please login again.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            fullWidth
                            onClick={handleRedirectLogin}
                            style={{ height: 46 }}
                            color="primary"
                            variant="contained"
                        >
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            {isShowPaymentWarning && (
                <Grid container direction="column" className={classes.upgradeUsage}>
                    <Grid item direction="column" container className={classes.usageWarning}>
                        <Grid item>
                            <Typography
                                style={{
                                    color: 'red'
                                }}
                                variant="body1"
                            >
                                Please upgrade your account to continue using this app.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                Your usage has ended at <strong>{getFriendlyWeekDate(merchant?.dueDate)}</strong>.
                                Please upgrade your account to continue using.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <UpgradePayment />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default React.memo(MainLayout);
