import React from 'react';
import ButtonBase from 'components/button/Base';

export function UpgradePayment() {
    return (
        <ButtonBase color="primary" variant="contained">
            Upgrade now
        </ButtonBase>
    );
}
