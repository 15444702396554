import moment from 'moment-timezone';
export const TIMEZONE = 'America/Toronto';

// moment.suppressDeprecationWarnings = true;
// moment.tz.setDefault(TIMEZONE);

const DATE_FORMAT = `YYYY-MM-DD`;
const TIME_FORMAT = `HH:mm:ss`;
const today = new Date();

export const DEFAULT_DATE = {
    dayOfWeek: moment(today).format(`ddd`),
    day: moment(today).format(`DD`),
    shortDate: moment(today).format(DATE_FORMAT),
    month: moment(today).format(`MMMM`),
    uct: moment(today).utc().format()
};

export const FRIENDLY_DATE_TIME_FORMAT = `dddd, DD MMMM YYYY`;
export const FRIENDLY_DATE_TIME_HOUR_FORMAT = `dddd, DD MMMM YYYY HH:mm:ss`;

export function getOffsetNumber(date = new Date()) {
    return moment(date).utcOffset() / 60;
}

export function getCurrentUTC(date) {
    if (!date) return null;
    return moment(date).utc().format();
}

export function getCurrentDate() {
    return moment().format();
}

export function getCurrentDateFormat(date) {
    return moment(date).format(DATE_FORMAT);
}

export function convertISODateToPickerDate(date) {
    return new Date(date);
}

export function isSameDate({ date1, date2 }) {
    return moment(date1).isSame(date2, 'day');
}

export function isHaveSameDate({ date1, date2 }) {
    return date1?.getUTCDate() === date2?.getUTCDate();
}

export function isSameUtcDate(date1, date2) {
    const start = moment(date1);
    const end = moment(date2);
    return start.isSame(end, 'date');
}

export function convertTimeToFloat(time) {
    const [hours, minutes] = time.split(':');
    return parseFloat(`${hours}.${Number(minutes) < 10 ? `0${Number(minutes)}` : minutes}`);
}

export function isAfterTime(time1, time2) {
    const newTime1 = getTotalMinutesByTime(time1);
    const newTime2 = getTotalMinutesByTime(time2);

    return newTime2 > newTime1;
}

// export function getShortTime(time) {
//     return isSameToday(time) ? moment(time).format(`h:mm`) : moment(time).format(`DD/MM`);
// }

export function getDay(date) {
    return moment(date).format('DD');
}

export function getMonth(date) {
    return moment(date).format('MMM');
}

export function getFriendlyTime(time) {
    return moment(time).format(`dddd, MMMM Do YYYY, h:mm A`);
}

export function getFriendlyShortTime(time) {
    return moment(time).format(`DD/MM/YYYY, h:mm A`);
}

export function getFriendlyDate(date) {
    return moment(date).format(`MMMM Do YYYY`);
}

export function getFriendlyWeekDate(date) {
    return moment(date).format(`dddd, MMMM Do YYYY`);
}

export function isSameToday(date) {
    const today = moment();
    return moment(date).isSame(today, 'day');
}

export function formatDate(date, format = DATE_FORMAT) {
    return moment(date).format(format);
}

export function getStartDateOffsetTimestamp({ offset = 0, unit = 'd' }) {
    let dateFrom = moment().subtract(offset, unit).utc().format(`YYYY-MM-DD`);
    return moment(`${dateFrom} 23:59:59`).unix();
}

export function getTime(datetime) {
    if (!datetime) return null;
    return moment(datetime).valueOf();
}

export function getDateJS(timestamp) {
    return new Date(Number(timestamp));
}

export function getNativeDate(datetime) {
    return moment(datetime).toDate();
}

export function getDateTime(timestamp) {
    const date = new Date(Number(timestamp));
    return moment(date).format(DATE_FORMAT);
}

export const getDatesBetweenDates = (startDate, endDate) => {
    let dates = [];
    //to avoid modifying the original date
    const theDate = new Date(startDate);
    while (theDate < endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
    }
    dates = [...dates, endDate];
    return dates;
};

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function getDateOfWeek(date) {
    return moment(date).format(`dddd`);
}

export const slideTimeOptions = () => {
    const today = new Date();
    const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);
    let dates = getDatesBetweenDates(today, lastDayOfYear);

    return dates.map((date) => {
        return {
            day: date.getDate(),
            dayOfWeek: days[date.getDay()],
            month: months[date.getMonth()],
            value: date
        };
    });
};

export function getMondayByCurrentDate({ currentDate, numOfWeek = 1 }) {
    return moment(currentDate).startOf('isoWeek').add(numOfWeek, 'week').format(DATE_FORMAT);
}

export function getCurrentMondayByDate(currentDate) {
    return moment(currentDate).startOf('isoWeek').format(DATE_FORMAT);
}

export function getUTCbyMerchantDatetimeFormat(date) {
    const utcDate = getAppendMerchantTimezoneToDate(date);
    return getDatetimeFormat(utcDate);
}

function correctHours(h) {
    return h < 10 ? '0' + h : h;
}

export function getAppendMerchantTimezoneToDate(date) {
    const offset = getOffsetNumber(date);
    if (offset === 0) return date;
    const dateBefore = getDateBefore({ date, numOfDay: 1 })?.format();

    return offset < 0
        ? `${date?.split(`T`)?.[0]}T${correctHours(Math.abs(offset))}:00:00Z`
        : `${dateBefore?.split(`T`)?.[0]}T${correctHours(24 - Math.abs(offset))}:00:00Z`;
}

export function getDatetimeFormat(date) {
    return moment(date).format(DATE_FORMAT);
}

export function getTimeFormat(date) {
    return moment(date).format(TIME_FORMAT);
}

export function getCurrentShortTime() {
    return moment().format('HH:mm');
}

export function generateDateFormatted(inputDate) {
    const date = moment(inputDate);
    return {
        dateOfWeek: date.format(`dddd`).toLowerCase(),
        date: date.format(DATE_FORMAT),
        label: date.format(`ddd DD MMM`),
        dateMonth: date.format(`DD MMM`),
        year: date.format(`YYYY`),
        friendlyName: date.format(`dddd, DD MMM YYYY`),
        timestamp: getTime(date).toString()
    };
}

export function generateNextDate({ startDate, nextDaysNumber }) {
    const arrs = Array.from(Array(nextDaysNumber).keys());
    const dateRanges = [];

    arrs.forEach((index) => {
        const date = moment(startDate).add(index, 'days');
        const dateFormatted = generateDateFormatted(date);
        dateRanges.push(dateFormatted);
    });

    return dateRanges;
}

export function getOffsetDays({ day1, day2 }) {
    let a = moment(day1);
    let b = moment(day2);
    let duration = moment.duration(a.diff(b));
    let days = Math.floor(duration.asDays());
    return days;
}

export function isAfter(day1, day2) {
    const days = getOffsetDays({ day1, day2 });
    return days < 0;
}

export function isAfterOrEqual(day1, day2) {
    const days = getOffsetDays({ day1, day2 });
    return days <= 0;
}

export function getDateAfter({ date, numOfDay }) {
    return moment(date).add(numOfDay, 'days');
}

export function getDateBefore({ date, numOfDay }) {
    return moment(date).subtract(numOfDay, 'days');
}

export function addHours({ time, numOfHours }) {
    return moment(time, 'hh:mm').add(numOfHours, 'hours').format('HH:mm');
}

export function getSuggestionDateEnd({ dateOfWeek, date = new Date() }) {
    const dateOfWeekToday = getDateOfWeek(date);
    if (dateOfWeekToday === dateOfWeek) return date;

    const day = days.findIndex((day) => day === dateOfWeek);

    return moment(date)
        .startOf('isoWeek')
        .add(day - 1, 'days')
        .add(1, 'weeks')
        .valueOf();
}

export function getTotalMinutesByTime(time) {
    const [hours, minutes = 0] = time?.split(':');
    return Number(hours) * 60 + Math.round(Number(`0.${minutes}`) * 100);
}

export function convertFloatToMinutes(number) {
    const [hours, minutes = 0] = number?.toString()?.split('.');
    return Number(hours) * 60 + Math.round(Number(`0.${minutes}`) * 100);
}

export function getCalendarStyleByStartTime({ startTime, duration, minutePerPx = 36 / 15 }) {
    const startTotalMinutes = convertFloatToMinutes(startTime);
    return {
        top: startTotalMinutes * minutePerPx,
        height: duration * minutePerPx
    };
}

export function getClientArrivedBookingStyleByStartTime({ startTime, duration, minutePerPx = 36 / 15 }) {
    const startTotalMinutes = convertFloatToMinutes(startTime);

    return {
        left: startTotalMinutes * minutePerPx,
        width: duration * minutePerPx
    };
}

export function getEndTimeByStartTimeAndDuration({ startTime, duration }) {
    const endTime = convertDurationAndStartTimeToDate(startTime, duration);
    return convertTimeToFloat(endTime);
}

export function getTopByCurrentMinutes({ date, minutePerPx = 36 / 15 }) {
    var hours = date.getHours();
    var minutes = date.getMinutes();

    return (hours * 60 + minutes) * minutePerPx;
}

export function getRangeDate({ date, numOfDay }) {
    const currentDate = moment(date).format(DATE_FORMAT);
    const lastDate = moment(date).add(numOfDay, 'days').format(DATE_FORMAT);
    return [currentDate, lastDate];
}

export function convertFloatingToDateTime(number) {
    const totalMinutes = convertFloatToMinutes(number);
    return convertMinsToHrsMins(totalMinutes);
}

export function convertDurationAndStartTimeToDate(startTime, duration) {
    const startTotalMinutes = convertFloatToMinutes(startTime);
    let totalMinutes = startTotalMinutes + duration;
    return convertMinsToHrsMins(totalMinutes);
}

export function convertMinsToHrsMins(mins, delimiter = ':') {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}${delimiter}${m}`;
}

export function generateOptionsMinutes(minutes = 30, min = 0, max = 24 * 60, isSmallerThanMax = false) {
    const options = [];
    if (isSmallerThanMax) {
        for (let i = min; i < max; i += minutes) {
            const time = convertMinsToHrsMins(i);
            options.push(time);
        }
    } else {
        for (let i = min; i <= max; i += minutes) {
            const time = convertMinsToHrsMins(i);
            options.push(time);
        }
    }

    return options;
}

export function generateNumberOptions(minutes, min, max) {
    const options = [];
    for (let i = min; i <= max; i += minutes) {
        options.push(i);
    }
    return options;
}

export function mapTimeToTime12(time) {
    return moment(time, `hh:mm`)
        .format(`hh:mm a`)
        ?.replace(/^0(?:0:0?)?/, '');
}

export function mapTimeToTime12WithoutAMPm(time) {
    return moment(time, `hh:mm`)
        .format(`hh:mm`)
        ?.replace(/^0(?:0:0?)?/, '');
}

export function convertTimeWithDurationToTime(time, duration) {
    const float = convertTimeToFloat(time);

    return convertDurationAndStartTimeToDate(float, duration);
}

export function getCalendarStyleByTimeShift({ timeStart, timeEnd, minutePerPx = 36 / 15 }) {
    const timeStartFloat = convertTimeToFloat(timeStart);
    const timeEndFloat = convertTimeToFloat(timeEnd);

    const startTotalMinutes = convertFloatToMinutes(timeStartFloat);
    const endTotalMinutes = convertFloatToMinutes(timeEndFloat);

    const duration = Math.abs(endTotalMinutes - startTotalMinutes);

    return {
        top: startTotalMinutes * minutePerPx,
        height: duration * minutePerPx
    };
}

export function getShortTime(datetime) {
    return moment(datetime).format('HH:mm');
}

export function formatNumRangeToTime(numRange) {
    try {
        return JSON.parse(numRange);
    } catch (err) {
        return null;
    }
}

export function getNumRangeTimeStart(numRange) {
    const numRangeFormatted = formatNumRangeToTime(numRange);
    if (!numRangeFormatted?.[0]) return '00:00';
    return convertFloatingToDateTime(numRangeFormatted?.[0]);
}

export function getNumRangeTimeEnd(numRange) {
    const numRangeFormatted = formatNumRangeToTime(numRange);

    if (!numRangeFormatted?.[1]) return '00:00';
    return convertFloatingToDateTime(numRangeFormatted?.[1]);
}

export function convertNumRangeToTime(num) {
    return num?.toString()?.replace('.', ':');
}

export function convertTimeToNum(time) {
    return time?.toString()?.replace(':', '.');
}

export function convertTimeToNumRange({ startTime, endTime }) {
    return `[${convertTimeToNum(startTime)},${convertTimeToNum(endTime)}]`;
}

export function convertTimeToMinutes(time) {
    const [hours, minutes] = time.split(':');

    return +hours * 60 + +minutes;
}

export function addMinutesToTime({ time, minutes }) {
    const convertMinutes = convertTimeToMinutes(time) + minutes;
    return convertMinsToHrsMins(convertMinutes);
}

export function getStartOfDay(startDate, tz) {
    return moment(startDate)
        .tz(tz || TIMEZONE)
        .clone()
        .startOf('day')
        .utc()
        .format();
}

export function getCurrentFriendlyTime(date, tz) {
    return moment(date)
        .tz(tz || TIMEZONE)
        .format(FRIENDLY_DATE_TIME_FORMAT);
}

export function convertStartTimeToTime12(startTime) {
    const minutes = convertFloatToMinutes(startTime);
    return mapTimeToTime12(convertMinsToHrsMins(minutes));
}

export function convertDurationToHrsMins(mins) {
    const h = Math.floor(mins / 60);
    const m = mins % 60;
    return `${h > 0 ? `${h}h` : ``}${m > 0 ? `${m}m` : ``}`;
}

export function getCurrentDateLimitTime(PRE_BOOKING_MINUTES = 0) {
    const time = moment(new Date()).format('HH:mm');
    const totalMinutes = getTotalMinutesByTime(time);
    return totalMinutes + PRE_BOOKING_MINUTES;
}

export function getDate(date, tz) {
    return moment(date)
        .tz(tz || TIMEZONE)
        .format(DATE_FORMAT);
}

export function getMonthText(date) {
    return moment(date).format(`MMM`);
}

export function getUtcISODateTime(date, time, tz) {
    const formatDate = getAppendMerchantTimezoneToDate(date, tz);

    const extraHours = convertTimeToFloat(time);

    if (extraHours?.toString()?.includes('.')) {
        let [hours, minutes] = extraHours?.toString()?.split('.');

        minutes = +minutes < 10 ? +minutes * 10 : +minutes;
        return moment(formatDate).add(+hours, 'hours').add(+minutes, 'minutes').utc().format();
    } else {
        return moment(formatDate).add(extraHours, 'hours').utc().format();
    }
}

export function mapSlots({ slots = [], tz }) {
    return slots?.map((slot) => ({
        ...slot,
        shortDate: getDate(slot.date, tz),
        dayOfWeek: getDateOfWeek(slot.date, tz),
        day: getDay(slot.date, tz),
        month: getMonth(slot.date, tz),
        value: slot.date
    }));
}

export function getNextCurrentSlot(slotInterval = 15) {
    const currentDay = new Date();
    const currentMinutes = currentDay.getHours() * 60 + currentDay.getMinutes();
    const maxMinutes = currentMinutes + slotInterval;

    let foundSlotMinutes = currentMinutes;

    for (let i = currentMinutes; i <= maxMinutes; i++) {
        if (i % slotInterval === 0) {
            foundSlotMinutes = i;
            break;
        }
    }

    return convertMinsToHrsMins(foundSlotMinutes);
}

export function toEndOfDay(dateInput, timezone = 'America/Toronto') {
    return moment(dateInput).tz(timezone).endOf('day').utc();
}
