import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Button, Chip, Avatar } from '@material-ui/core';
import {
    usePermissionsOptions,
    useMerchantSlotInterval,
    useTimeZoneOptions,
    useApplicationFeeOptions,
    useCancelBeforeBookingMinutesOptions,
    useBeforeBookingMinutesOptions,
    userPaymentCurrencyOptions,
    useBookingApplicationFeeOptions
} from 'hooks/merchant';
import NativeSelect from 'components/select/Native';
import ReactSelect from 'react-select';
import { TIMEZONE, toEndOfDay } from 'utils/timing';
import InputBase from 'components/input/Form';
import { getImageUrl } from 'utils/image';
import { useDispatch } from 'react-redux';
import * as settingActions from './actions';
import { MerchantState, AVATAR_EXTENSIONS, DEFAULT_CURRENCY } from 'const';
import { getMerchantStateColor, getMerchantStateLabel } from 'utils/app/merchant';
import { useDropzone } from 'react-dropzone';
import IconCameraSVG from 'assets/images/svg/IconCameraSVG';
import Spinner from 'components/spinner';
import * as merchantActions from 'merchant/actions';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import useMediaQueries from 'hooks/useMediaQueries';

const useStyles = makeStyles((theme) => ({
    root: {},

    options: {
        flex: 1
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    btnActivate: {
        background: theme.colors.green,
        '&:hover': {
            background: theme.colors.green,
            opacity: 0.5
        }
    },
    btnDeactivate: {
        background: theme.colors.red,
        '&:hover': {
            background: theme.colors.red,
            opacity: 0.5
        }
    },
    btnPendingPayment: {
        background: theme.colors.sun,
        '&:hover': {
            background: theme.colors.sun,
            opacity: 0.5
        }
    },
    avatar: {
        '& .alreadyHaveImage': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: 'hidden',
            left: 0,
            top: 0,
            '& svg': {
                width: 30,
                height: 30,
                '& path': {
                    fill: theme.palette.common.white
                }
            },
            '&  div': {
                outline: 'none'
            }
        },
        '&:hover': {
            '& .alreadyHaveImage': {
                visibility: 'visible'
            }
        }
    },
    upload: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',

        background: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            opacity: 0.8
        },
        '& svg': {
            width: 30,
            height: 30
        },
        '&  div': {
            outline: 'none'
        }
    },
    imageUploading: {
        opacity: 0.5,
        background: theme.colors.gunPower
    },
    noThumbnail: {
        background: `${theme.palette.common.white} !important`,
        border: `1px dashed ${theme.colors.border}`,
        '&:hover': {
            background: `${theme.palette.primary.light} !important`
        }
    }
}));

function Setting({ merchant }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const paymentCurrencyOptions = userPaymentCurrencyOptions();
    const permissionOptions = usePermissionsOptions();
    const slotIntervalOptions = useMerchantSlotInterval();
    const timezoneOptions = useTimeZoneOptions();
    const applicationFeeOptions = useApplicationFeeOptions();
    const bookingApplicationFeeOptions = useBookingApplicationFeeOptions();
    const beforeBookingMinutesOptions = useBeforeBookingMinutesOptions();
    const cancelBeforeBookingMinutesOptions = useCancelBeforeBookingMinutesOptions();
    const [selectedSlotInterval, setSelectedSlotInterval] = React.useState(null);
    const [permissions, setPermissions] = React.useState([]);
    const [timezone, setTimezone] = React.useState(null);
    const [cancelBefore, setCancelBefore] = React.useState(null);
    const [bookingBefore, setBookingBefore] = React.useState(null);
    const [address, setAddress] = React.useState('');
    const [caid, setCaid] = React.useState('');
    const [applicationFee, setApplicationFee] = React.useState(null);
    const [bookingApplicationFeeAmount, setBookingApplicationFeeAmount] = React.useState(null);
    const [contactEmail, setContactEmail] = React.useState('');
    const [ownerEmail, setOwnerEmail] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isActivate, setIsActivate] = React.useState(false);
    const [isDeactivate, setIsDeactivate] = React.useState(false);
    const [isPendingPayment, setIsPendingPayment] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [slug, setSlug] = React.useState('');
    const [reviewUrl, setReviewUrl] = React.useState('');
    const [smsRemaining, setSmsRemaining] = React.useState(0);
    const [merchantState, setMerchantState] = React.useState(merchant?.state);
    const [taxRate, setTaxRate] = React.useState(0);
    const [taxNumber, setTaxNumber] = React.useState('');
    const [merchantMap, setMerchantMap] = React.useState('');
    const [isUploading, setIsUploading] = React.useState(false);
    const [incomePassword, setIncomePassword] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [lat, setLat] = React.useState('');
    const [lng, setLng] = React.useState('');
    const [paymentCurrency, setPaymentCurrency] = React.useState(paymentCurrencyOptions?.[0]);
    const [discountPercent, setDiscountPercent] = React.useState(0);
    const [historyBookingLimit, setHistoryBookingLimit] = React.useState(0);
    const [price, setPrice] = React.useState(merchant?.price || 0);
    const [dueDate, setDueDate] = React.useState(moment(merchant?.dueDate));
    const { isMobile } = useMediaQueries();
    const [displayPhone, setDisplayPhone] = React.useState(merchant?.phone);

    const merchantLogo = React.useMemo(() => {
        return merchant?.logo;
    }, [merchant]);

    const [thumbnail, setThumbnail] = React.useState(merchantLogo);

    React.useEffect(() => {
        if (merchantLogo) {
            setThumbnail(getImageUrl(merchantLogo));
        } else {
            setThumbnail();
        }
    }, [merchantLogo]);

    const merchantLabel = React.useMemo(() => {
        return getMerchantStateLabel(merchantState);
    }, [merchantState]);

    const merchantColor = React.useMemo(() => {
        return getMerchantStateColor({ state: merchantState, theme });
    }, [merchantState, theme]);

    console.log('bookingApplicationFeeAmount', bookingApplicationFeeAmount);

    const onRejectedHandler = React.useCallback((rejectedFiles) => {}, []);

    const onDropAcceptedHandler = React.useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles?.[0];
            if (file) {
                setThumbnail(URL.createObjectURL(file));
            }
            setIsUploading(true);

            dispatch(
                merchantActions.uploadAvatar({
                    merchantId: +merchant?.id,
                    file,
                    successCallback: (merchant) => {
                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        setIsUploading(false);
                        console.log('upload merchant failed');
                    }
                })
            );
        },
        [merchant, dispatch]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: onDropAcceptedHandler,
        onDropRejected: onRejectedHandler,
        accept: AVATAR_EXTENSIONS
    });

    React.useEffect(() => {
        setPermissions(
            merchant?.permissions?.map((permission) => ({
                label: permission,
                value: permission
            }))
        );
        setSelectedSlotInterval(merchant?.slotInterval || 15);
        setTimezone(merchant?.timezone || TIMEZONE);
        setCancelBefore(merchant?.bookingCancelBeforeMinutes || 60);
        setBookingBefore(merchant?.bookingBeforeMinutes || 60);
        setAddress(merchant?.address);
        setCaid(merchant?.caid);
        setApplicationFee(merchant?.applicationFeeAmount);
        setBookingApplicationFeeAmount(merchant?.bookingApplicationFeeAmount || 1);
        setContactEmail(merchant?.contactEmail);
        setOwnerEmail(merchant?.ownerEmail);
        setSlug(merchant?.siteName);
        setTitle(merchant?.title);
        setReviewUrl(merchant?.reviewUrl);
        setSmsRemaining(merchant?.smsRemaining);
        setMerchantState(merchant?.state);
        setTaxRate(merchant?.taxRate);
        setTaxNumber(merchant?.taxNumber);
        setMerchantMap(merchant?.merchantMap);
        setCurrency(merchant?.currency);
        setLat(merchant?.lat);
        setLng(merchant?.lng);
        setCurrency(merchant?.currency);
        setDiscountPercent(merchant?.discountPercent);
        setPaymentCurrency(paymentCurrencyOptions?.find((o) => o?.value === merchant?.paymentCurrency));
        setHistoryBookingLimit(merchant?.historyBookingLimit);
        setPrice(merchant?.price);
        setDueDate(moment(merchant?.dueDate));
        setDisplayPhone(merchant?.phone);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchant]);

    const handleSlotIntervalChange = React.useCallback((e) => {
        setSelectedSlotInterval(e.target.value);
    }, []);

    const handlePermissionChange = React.useCallback((options) => {
        setPermissions(options);
    }, []);

    const handlePaymentCurrencyChange = React.useCallback((option) => {
        setPaymentCurrency(option);
    }, []);

    const handleTimezoneChange = React.useCallback((e) => {
        setTimezone(e.target?.value);
    }, []);

    const handleBookingBeforeChange = React.useCallback((e) => {
        setBookingBefore(e.target?.value);
    }, []);

    const handleCancelBeforeChange = React.useCallback((e) => {
        setCancelBefore(e.target?.value);
    }, []);

    const handleApplicationFeeChange = React.useCallback((e) => {
        setApplicationFee(e.target?.value);
    }, []);

    const handleBookingApplicationFeeChange = React.useCallback((e) => {
        setBookingApplicationFeeAmount(e.target?.value);
    }, []);

    const handleSave = React.useCallback(() => {
        const updateBody = {
            permissions: permissions?.map((option) => option?.value),
            address,
            email: contactEmail,
            ownerEmail,
            applicationFeeAmount: +applicationFee,
            caid,
            bookingApplicationFeeAmount: +bookingApplicationFeeAmount,
            slotInterval: +selectedSlotInterval,
            timezone,
            bookingCancelBeforeMinutes: +cancelBefore,
            bookingBeforeMinutes: +bookingBefore,
            title,
            slug,
            reviewUrl,
            smsRemaining: +smsRemaining,
            taxRate: +taxRate,
            discountPercent: +discountPercent,
            taxNumber,
            merchantMap,
            incomePassword,
            currency: currency || DEFAULT_CURRENCY,
            historyBookingLimit: +historyBookingLimit,
            lng: lng || null,
            lat: lat || null,
            paymentCurrency: paymentCurrency?.value || 'cad',
            price: +price,
            displayPhone
        };
        setIsLoading(true);
        dispatch(
            settingActions?.updateMerchant({
                merchantId: merchant?.id,
                body: updateBody,
                successCallback: () => {
                    setIsLoading(false);
                    console.log('update merchant success');
                },
                errorCallback: () => {
                    setIsLoading(false);
                    console.log('update merchant failed');
                }
            })
        );
    }, [
        displayPhone,
        ownerEmail,
        historyBookingLimit,
        lat,
        lng,
        paymentCurrency,
        discountPercent,
        taxRate,
        taxNumber,
        merchantMap,
        smsRemaining,
        merchant,
        dispatch,
        address,
        permissions,
        contactEmail,
        applicationFee,
        caid,
        selectedSlotInterval,
        timezone,
        cancelBefore,
        bookingBefore,
        slug,
        title,
        reviewUrl,
        incomePassword,
        currency,
        bookingApplicationFeeAmount,
        price
    ]);

    console.log('dueDate', dueDate.toISOString());

    const handleActivate = React.useCallback(() => {
        const updateBody = {
            state: MerchantState.Active
        };
        setIsActivate(true);
        dispatch(
            settingActions?.updateMerchant({
                merchantId: merchant?.id,
                body: updateBody,
                successCallback: () => {
                    setIsActivate(false);
                    console.log('update merchant success');
                    setMerchantState(MerchantState.Active);
                },
                errorCallback: () => {
                    setIsActivate(false);
                    console.log('update merchant failed');
                }
            })
        );
    }, [dispatch, merchant]);

    const handleDeactivate = React.useCallback(() => {
        const updateBody = {
            state: MerchantState.Deactivate
        };
        setIsDeactivate(true);
        dispatch(
            settingActions?.updateMerchant({
                merchantId: merchant?.id,
                body: updateBody,
                successCallback: () => {
                    setIsDeactivate(false);
                    console.log('update merchant success');
                    setMerchantState(MerchantState.Deactivate);
                },
                errorCallback: () => {
                    setIsDeactivate(false);
                    console.log('update merchant failed');
                }
            })
        );
    }, [dispatch, merchant]);

    const handlePaymentPending = React.useCallback(() => {
        const updateBody = {
            state: MerchantState.PendingPayment
        };
        setIsPendingPayment(true);
        dispatch(
            settingActions?.updateMerchant({
                merchantId: merchant?.id,
                body: updateBody,
                successCallback: () => {
                    setIsPendingPayment(false);
                    console.log('update merchant success');
                    setMerchantState(MerchantState.PendingPayment);
                },
                errorCallback: () => {
                    setIsPendingPayment(false);
                    console.log('update merchant failed');
                }
            })
        );
    }, [dispatch, merchant]);

    const stopPropagation = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    console.log('thumbnail', thumbnail);
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography variant="body2">Current Status:</Typography>
                            </Grid>
                            <Grid item>
                                <Chip
                                    size="small"
                                    label={merchantLabel}
                                    style={{ background: merchantColor, color: theme.palette.common.white }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2} justifyContent="flex-end">
                            {merchantState !== MerchantState.Active && (
                                <Grid item className={classes.btnSubmit}>
                                    <Button
                                        className={classes.btnActivate}
                                        size="small"
                                        onClick={handleActivate}
                                        width={100}
                                        variant="contained"
                                        color="primary"
                                        disabled={isActivate}
                                    >
                                        {isActivate ? (
                                            <CircularProgress size={20} className={classes.buttonProgress} />
                                        ) : (
                                            'Activate'
                                        )}
                                    </Button>
                                </Grid>
                            )}
                            {MerchantState.Deactivate !== merchantState && (
                                <Grid item className={classes.btnSubmit}>
                                    <Button
                                        className={classes.btnDeactivate}
                                        size="small"
                                        onClick={handleDeactivate}
                                        width={100}
                                        variant="contained"
                                        color="primary"
                                        disabled={isDeactivate}
                                    >
                                        {isDeactivate ? (
                                            <CircularProgress size={20} className={classes.buttonProgress} />
                                        ) : (
                                            'Deactivate'
                                        )}
                                    </Button>
                                </Grid>
                            )}
                            {MerchantState.PendingPayment !== merchantState && (
                                <Grid item className={classes.btnSubmit}>
                                    <Button
                                        size="small"
                                        onClick={handlePaymentPending}
                                        width={100}
                                        variant="contained"
                                        color="primary"
                                        className={classes.btnPendingPayment}
                                        disabled={isPendingPayment}
                                    >
                                        {isPendingPayment ? (
                                            <CircularProgress size={20} className={classes.buttonProgress} />
                                        ) : (
                                            'Pending Payment'
                                        )}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item className={classes.btnSubmit}>
                                <Button
                                    disabled={isLoading}
                                    size="small"
                                    onClick={handleSave}
                                    variant="contained"
                                    color="primary"
                                >
                                    {isLoading ? (
                                        <CircularProgress size={20} className={classes.buttonProgress} />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={3}>
                    <Grid item className={classes.options}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Slot Interval:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NativeSelect
                                            onChange={handleSlotIntervalChange}
                                            selectedValue={selectedSlotInterval}
                                            options={slotIntervalOptions}
                                            placeholder={`Choose slot interval option`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Allow Cancel Before:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NativeSelect
                                            onChange={handleCancelBeforeChange}
                                            selectedValue={cancelBefore}
                                            options={cancelBeforeBookingMinutesOptions}
                                            placeholder={`Choose time`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Allow Booking Before:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NativeSelect
                                            onChange={handleBookingBeforeChange}
                                            selectedValue={bookingBefore}
                                            options={beforeBookingMinutesOptions}
                                            placeholder={`Choose time`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Price:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Price to charge merchant'}
                                            name="price"
                                            value={price || ''}
                                            onChange={(e) => setPrice(e?.target?.value)}
                                            type="number"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Due Date:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <div className={classes.picker}>
                                                <DatePicker
                                                    variant={isMobile ? 'dialog' : 'inline'}
                                                    label="Due date"
                                                    value={dueDate}
                                                    onChange={(date) => {
                                                        setDueDate(toEndOfDay(date, merchant?.timezone || TIMEZONE));
                                                    }}
                                                    disableToolbar
                                                    format="DD/MM/YYYY"
                                                    autoOk={true}
                                                />
                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Permissions: </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReactSelect
                                            onChange={handlePermissionChange}
                                            value={permissions}
                                            options={permissionOptions}
                                            placeholder={`Choose permission option`}
                                            isMulti
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Time zone:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NativeSelect
                                            onChange={handleTimezoneChange}
                                            selectedValue={timezone}
                                            options={timezoneOptions}
                                            placeholder={`Choose timezone`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Address</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Merchant address'}
                                            name="address"
                                            value={address || ''}
                                            onChange={(e) => setAddress(e?.target?.value)}
                                            error={!address}
                                            errorText={'Address cannot be empty'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Stripe CAID:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Stripe Caid'}
                                            name="caid"
                                            value={caid || ''}
                                            onChange={(e) => setCaid(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Stripe Gift Application Fee:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NativeSelect
                                            onChange={handleApplicationFeeChange}
                                            selectedValue={applicationFee}
                                            options={applicationFeeOptions}
                                            placeholder={`Choose option`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Stripe Booking Application Fee:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NativeSelect
                                            onChange={handleBookingApplicationFeeChange}
                                            selectedValue={bookingApplicationFeeAmount}
                                            options={bookingApplicationFeeOptions}
                                            placeholder={`Choose option`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Payment Currency: </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReactSelect
                                            onChange={handlePaymentCurrencyChange}
                                            value={paymentCurrency}
                                            options={paymentCurrencyOptions}
                                            placeholder={`Choose payment currency option`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Contact Email:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Email'}
                                            name="contactEmail"
                                            value={contactEmail || ''}
                                            onChange={(e) => setContactEmail(e?.target?.value)}
                                            error={!contactEmail}
                                            errorText={'Contact email cannot be empty'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Display Phone</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'+1 (234) 456-7899'}
                                            name="displayPhone"
                                            value={displayPhone || ''}
                                            onChange={(e) => setDisplayPhone(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Owner Email:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Email'}
                                            name="ownerEmail"
                                            value={ownerEmail || ''}
                                            onChange={(e) => setOwnerEmail(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Merchant Title</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Merchant title'}
                                            name="title"
                                            value={title || ''}
                                            onChange={(e) => setTitle(e?.target?.value)}
                                            error={!title}
                                            errorText={'Merchant title cannot be empty'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Merchant slug</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Merchant slug'}
                                            name="slug"
                                            value={slug || ''}
                                            onChange={(e) => setSlug(e?.target?.value)}
                                            error={!slug}
                                            errorText={'Merchant slug cannot be empty'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Review url</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Merchant review url'}
                                            name="reviewUrl"
                                            value={reviewUrl || ''}
                                            onChange={(e) => setReviewUrl(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Tax Rate</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Tax rate'}
                                            name="taxRate"
                                            value={taxRate || ''}
                                            onChange={(e) => setTaxRate(e?.target?.value)}
                                            type="number"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Discount Percent</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Discount percent'}
                                            name="discountPercent"
                                            value={discountPercent || ''}
                                            onChange={(e) => setDiscountPercent(e?.target?.value)}
                                            type="number"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Currency</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Currency'}
                                            name="currency"
                                            value={currency || ''}
                                            onChange={(e) => setCurrency(e?.target?.value)}
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">History booking limit</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'History booking limit'}
                                            name="historyBookingLimit"
                                            value={historyBookingLimit || ''}
                                            onChange={(e) => setHistoryBookingLimit(e?.target?.value)}
                                            type="number"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Tax number</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Tax Number'}
                                            name="taxNumber"
                                            value={taxNumber || ''}
                                            onChange={(e) => setTaxNumber(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Location embed map</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Merchant review url'}
                                            name="merchantMap"
                                            value={merchantMap || ''}
                                            onChange={(e) => setMerchantMap(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Sms remaining</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            type={'number'}
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Sms count'}
                                            name="smsRemaining"
                                            value={smsRemaining}
                                            onChange={(e) => setSmsRemaining(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Income password</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'Income password'}
                                            name="incomePassword"
                                            value={incomePassword}
                                            onChange={(e) => setIncomePassword(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Lat position</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'lat position'}
                                            name="lat"
                                            value={lat || ''}
                                            onChange={(e) => setLat(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" wrap="nowrap" alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">Lng position</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            placeholder={'lng position'}
                                            name="lng"
                                            value={lng || ''}
                                            onChange={(e) => setLng(e?.target?.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center" spacing={2}>
                            <Grid item>
                                <div
                                    className={classes.avatar}
                                    style={{ position: 'relative', width: 200, height: 200 }}
                                >
                                    <Avatar
                                        className={`${classes.upload} ${isUploading ? classes.imageUploading : ''} ${
                                            !thumbnail ? classes.noThumbnail : ''
                                        }`}
                                        src={thumbnail}
                                        size="large"
                                    >
                                        <div onClick={stopPropagation}>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <IconCameraSVG />
                                            </div>
                                        </div>
                                    </Avatar>
                                    {thumbnail && (
                                        <div onClick={stopPropagation} className={'alreadyHaveImage'}>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <IconCameraSVG />
                                            </div>
                                        </div>
                                    )}
                                    {isUploading && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                zIndex: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                left: 3,
                                                top: 3
                                            }}
                                        >
                                            <Spinner size={40} thick={4} />
                                        </div>
                                    )}
                                </div>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{merchant?.title}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Setting;
